import { css } from 'styled-components'

import { hypeFont } from '../fonts/hype'
import { interFont } from '../fonts/inter'

import type { Theme } from 'theme'

export const generateTextStyles = ({
  Mobile,
  Tablet,
  Desktop,
}: Theme['Typography'][keyof Theme['Typography']]) => {
  const { 'font-family': desktopFontFamily, ...desktopStyles } = Desktop
  const { 'font-family': tabletFontFamily, ...tabletStyles } = Tablet
  const { 'font-family': mobileFontFamily, ...mobileStyles } = Mobile

  let mobileNextFontStyle

  if (mobileFontFamily === 'Hype 1400') {
    mobileNextFontStyle = hypeFont.style
  } else if (mobileFontFamily === 'Helvetica Now Display') {
    mobileNextFontStyle = interFont.style
  }

  const mobileCss = css`
    ${mobileStyles}
    ${mobileNextFontStyle}
  `

  let tabletNextFontStyle

  if (tabletFontFamily === 'Hype 1400') {
    tabletNextFontStyle = hypeFont.style
  } else if (tabletFontFamily === 'Helvetica Now Display') {
    tabletNextFontStyle = interFont.style
  }

  const tabletCss = css`
    ${tabletStyles}
    ${tabletNextFontStyle}
  `

  let desktopNextFontStyle

  if (desktopFontFamily === 'Hype 1400') {
    desktopNextFontStyle = hypeFont.style
  } else if (desktopFontFamily === 'Helvetica Now Display') {
    desktopNextFontStyle = interFont.style
  }

  const desktopCss = css`
    ${desktopStyles}
    ${desktopNextFontStyle}
  `

  return css`
    ${mobileCss}

    @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
      ${tabletCss}
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.UI.BreakpointPx.Desktop}) {
      ${desktopCss}
    }
  `
}

import { captureException } from '@sentry/nextjs'
import useAxios from 'axios-hooks'
import {
  addHours,
  differenceInMinutes,
  isAfter,
  isBefore,
  isPast,
  isWithinInterval,
} from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import { StoryblokMatchLiveUpdateType } from '../../storyblok/content-types/match'
import { useStoryblokTranslationsContext } from '../../storyblok/context/translations-provider'
import { useTranslations } from '../../storyblok/hooks/useTranslations'
import { setTimeOnDate } from '../utils/setTimeOnDate'

import type { SbMatchData } from '../../storyblok/components/match/Match.types'
import type { SbMatchLiveUpdateData } from '../../storyblok/components/match-live-update/MatchLiveUpdate.types'
import type { MatchEvent } from '../components/match-time-line'

type UseLiveMatchReturnType = {
  sortedUpdates: SbMatchLiveUpdateData[]
  matchEvents: Map<number, MatchEvent[]>
  lastUpdateTime?: Date
  matchIsLive?: 'live' | 'half-time' | 'full-time' | 'penalty-shootout'
  matchLiveLabel: string
  matchTiming?: {
    minute: number
    extraTime: number
  }
  penaltyShootoutGoals: {
    home: number
    away: number
  }
  matchScores: {
    home?: string
    away?: string
  }
}

export const useLiveMatch = (match?: SbMatchData): UseLiveMatchReturnType => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const router = useRouter()

  const [game, setGame] = useState<SbMatchData | undefined>(match)

  const [, getMatch] = useAxios(
    {
      url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/storyblok/live-data`,
      params: {
        slug: router.asPath,
      },
    },
    {
      manual: true,
    }
  )

  useEffect(() => {
    const fetchMatch = async () => {
      try {
        const { data } = await getMatch()

        setGame(data.story.content)
      } catch (error) {
        console.error(error)
        captureException(error)
      }
    }

    const gameEndTime = game?.full_time || game?.extra_time_full_time
    // Stop streaming 2 hours after the game has ended
    const shouldStopStreaming = gameEndTime
      ? isPast(addHours(fromZonedTime(new Date(gameEndTime), 'UTC'), 2))
      : false

    if (!shouldStopStreaming) {
      const interval = setInterval(() => {
        fetchMatch()
      }, 30000)

      return () => clearInterval(interval)
    }
  }, [])

  // Kickoff is a combination of matchday and time attribute
  const kickoffTime = useMemo(() => {
    if (!game) {
      return new Date()
    }

    let day = new Date(game.matchday)

    // Add time to kickoff
    if (game.time) {
      day = setTimeOnDate(day, game.time)
    }

    return day
  }, [game])

  const sortedUpdates = useMemo(() => {
    if (!game?.live_updates) {
      return []
    }

    return game.live_updates.sort((a, b) => {
      const aDate = new Date(a.created_at)
      const bDate = new Date(b.created_at)

      const kickoffDate = new Date(kickoffTime)
      const fullTimeDate = game.full_time ? new Date(game.full_time) : null
      const extraTimeFullTimeDate = game.extra_time_full_time
        ? new Date(game.extra_time_full_time)
        : null
      const halfTimeDate = game.half_time ? new Date(game.half_time) : null
      const secondHalfDate = game.second_half
        ? new Date(game.second_half)
        : null

      // Helper to determine if 'minute' is empty
      const isMinuteEmpty = (update: SbMatchLiveUpdateData) => {
        return (
          update.minute === '' ||
          update.minute === null ||
          update.minute === undefined
        )
      }

      // Helper to check if an update is post-game (after full time/extra time and no minute value)
      const isPostMatchUpdate = (date: Date, update: SbMatchLiveUpdateData) => {
        if (!isMinuteEmpty(update)) return false // Only consider updates without minute
        return extraTimeFullTimeDate
          ? date > extraTimeFullTimeDate
          : fullTimeDate
          ? date > fullTimeDate
          : false
      }

      // Helper to check if an update happened during half-time
      const isHalfTimeUpdate = (date: Date, update: SbMatchLiveUpdateData) => {
        return (
          isMinuteEmpty(update) &&
          (secondHalfDate
            ? isWithinInterval(date, {
                start: kickoffDate,
                end: secondHalfDate,
              })
            : halfTimeDate
            ? isAfter(date, halfTimeDate)
            : false)
        )
      }

      // Handle post-game updates without minute
      const aIsPostMatch = isPostMatchUpdate(aDate, a)
      const bIsPostMatch = isPostMatchUpdate(bDate, b)

      if (aIsPostMatch && bIsPostMatch) {
        return bDate.getTime() - aDate.getTime() // Sort post-game by newest
      }
      if (aIsPostMatch) return -1 // Post-game comes first
      if (bIsPostMatch) return 1

      // Handle half-time updates without minute
      const aIsHalfTime = isHalfTimeUpdate(aDate, a)
      const bIsHalfTime = isHalfTimeUpdate(bDate, b)

      if (aIsHalfTime && bIsHalfTime) {
        return bDate.getTime() - aDate.getTime() // Sort half-time by newest
      }

      // Handle in-game updates with minute
      const aHasMinute = !isMinuteEmpty(a)
      const bHasMinute = !isMinuteEmpty(b)

      // Assign a "virtual minute" of 45.5 for half-time updates
      const getMinuteValue = (
        update: SbMatchLiveUpdateData,
        isHalfTime: boolean
      ) => {
        if (isHalfTime) return 45.5
        if (!isMinuteEmpty(update))
          return Number.parseInt(update.minute + '', 10)
        return null
      }

      const aMinute = getMinuteValue(a, aIsHalfTime)
      const bMinute = getMinuteValue(b, bIsHalfTime)

      if (aMinute && bMinute) {
        if (aMinute === bMinute) {
          const aExtraMinutes = a.minutes_extra
            ? Number.parseInt(a.minutes_extra + '', 10)
            : 0
          const bExtraMinutes = b.minutes_extra
            ? Number.parseInt(b.minutes_extra + '', 10)
            : 0
          return bExtraMinutes - aExtraMinutes // Sort by extra minutes
        }
        return bMinute - aMinute // Sort by minute
      }

      // If one update has a minute and the other doesn't
      if (aHasMinute) return -1 // In-game updates come first
      if (bHasMinute) return 1

      // Fallback: sort by created_at for other updates without minute
      return bDate.getTime() - aDate.getTime()
    })
  }, [
    kickoffTime,
    game?.extra_time_full_time,
    game?.full_time,
    game?.half_time,
    game?.live_updates,
    game?.second_half,
  ])

  const matchEvents = useMemo(() => {
    const supportedUpdates = new Set([
      StoryblokMatchLiveUpdateType.Goal,
      StoryblokMatchLiveUpdateType.YellowCard,
      StoryblokMatchLiveUpdateType.RedCard,
      StoryblokMatchLiveUpdateType.PlayerChange,
    ])

    if (!game) {
      return new Map<number, MatchEvent[]>()
    }

    const filteredUpdates = sortedUpdates.filter((update) =>
      supportedUpdates.has(update.type)
    )

    const events: Map<number, MatchEvent[]> = new Map()

    filteredUpdates.forEach((update) => {
      const key = Number.parseInt(update.minute ?? '')

      if (!key || Number.isNaN(key) || key <= 0) {
        return
      }

      if (update.type === StoryblokMatchLiveUpdateType.PlayerChange) {
        const newEvents: MatchEvent[] = [
          {
            minute: key,
            type: 'player_out',
            team: update.team?.uuid === game.home_team.uuid ? 'home' : 'away',
            text: `${update.player_out} (${update.minute}${
              update.minutes_extra ? `+${update.minutes_extra}’` : '’'
            })`.trim(),
          },
          {
            minute: key,
            type: 'player_in',
            team: update.team?.uuid === game.home_team.uuid ? 'home' : 'away',
            text: `${update.player_in} (${update.minute}${
              update.minutes_extra ? `+${update.minutes_extra}’` : '’'
            })`.trim(),
          },
        ]

        if (events.has(key)) {
          events.get(key)!.push(...newEvents)
        } else {
          events.set(key, newEvents)
        }
      }

      const newEvent: MatchEvent = {
        minute: key,
        type: update.type,
        team: update.team?.uuid === game.home_team.uuid ? 'home' : 'away',
        text: `${update.player ?? ''} (${update.minute}${
          update.minutes_extra ? `+${update.minutes_extra}’` : '’'
        })`.trim(),
      }

      if (events.has(key)) {
        events.get(key)!.push(newEvent)
      } else {
        events.set(key, [newEvent])
      }
    })

    return events
  }, [game, sortedUpdates])

  const lastUpdateTime = sortedUpdates.at(0)?.created_at
    ? fromZonedTime(new Date(sortedUpdates.at(0)!.created_at), 'UTC')
    : undefined

  const matchIsLive = useMemo(() => {
    if (!game) {
      return 'full-time'
    }

    // Check if penalty shootout is active
    if (
      game.penalty_shootout_start &&
      !game.penalty_shootout_end &&
      isAfter(
        new Date(),
        fromZonedTime(new Date(game.penalty_shootout_start), 'UTC')
      )
    ) {
      return 'penalty-shootout'
    }

    // Before the match starts, we're not live
    if (isBefore(new Date(), kickoffTime)) {
      return undefined
    }

    // After the game ends, we're not live
    if (
      isAfter(new Date(), fromZonedTime(new Date(game.full_time), 'UTC')) &&
      !game.extra_time_first_half
    ) {
      return 'full-time'
    }

    // If we're in extra time, after it ends, we're not live
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (
      game.extra_time_full_time &&
      isAfter(
        new Date(),
        fromZonedTime(new Date(game.extra_time_full_time), 'UTC')
      )
    ) {
      return 'full-time'
    }

    if (
      (game.half_time && !game.second_half) ||
      (game.extra_time_half_time && !game.extra_time_second_half)
    ) {
      return 'half-time'
    }

    // Otherwise we're live
    return 'live'
  }, [kickoffTime, game])

  const matchLiveLabel = useMemo(() => {
    const now = new Date()

    if (!game) {
      return ''
    }

    if (
      game.penalty_shootout_start &&
      !game.penalty_shootout_end &&
      isAfter(
        new Date(),
        fromZonedTime(new Date(game.penalty_shootout_start), 'UTC')
      )
    ) {
      return t('match_live_label_penalties')
    }

    if (
      game.extra_time_second_half &&
      isAfter(now, fromZonedTime(new Date(game.extra_time_second_half), 'UTC'))
    ) {
      return t('match_live_label_extra_time')
    }

    if (
      game.extra_time_half_time &&
      (!game.extra_time_second_half ||
        isWithinInterval(now, {
          start: fromZonedTime(new Date(game.extra_time_half_time), 'UTC'),
          end: fromZonedTime(new Date(game.extra_time_second_half), 'UTC'),
        }))
    ) {
      return t('match_live_label_half_time')
    }

    if (
      game.extra_time_first_half &&
      isAfter(now, fromZonedTime(new Date(game.extra_time_first_half), 'UTC'))
    ) {
      return t('match_live_label_extra_time')
    }

    if (
      game.second_half &&
      isAfter(now, fromZonedTime(new Date(game.second_half), 'UTC'))
    ) {
      return t('match_live_label_second_half')
    }

    if (
      game.half_time &&
      (!game.second_half ||
        isWithinInterval(now, {
          start: fromZonedTime(new Date(game.half_time), 'UTC'),
          end: fromZonedTime(new Date(game.second_half), 'UTC'),
        }))
    ) {
      return t('match_live_label_half_time')
    }

    return t('match_live_label_first_half')
  }, [game, t])

  // Calculate the current minute based on timestamps and the current time
  const matchTiming = useMemo(() => {
    const now = new Date()

    if (!game) {
      return {
        minute: 0,
        extraTime: 0,
      }
    }

    if (
      game.penalty_shootout_start &&
      !game.penalty_shootout_end &&
      isAfter(
        new Date(),
        fromZonedTime(new Date(game.penalty_shootout_start), 'UTC')
      )
    ) {
      return undefined
    }

    // Second half extra time
    if (game.extra_time_second_half && !game.extra_time_full_time) {
      const difference = differenceInMinutes(
        now,
        fromZonedTime(new Date(game.extra_time_second_half), 'UTC')
      )

      return {
        minute: Math.min(105 + difference, 120),
        extraTime: difference > 15 ? difference - 15 : 0,
      }
    }

    // Half time extra time
    if (game.extra_time_half_time && !game.extra_time_second_half) {
      return undefined
    }

    // First half extra time
    if (game.extra_time_first_half && !game.extra_time_half_time) {
      const difference = differenceInMinutes(
        now,
        fromZonedTime(new Date(game.extra_time_first_half), 'UTC')
      )

      return {
        minute: Math.min(90 + difference, 105),
        extraTime: difference > 15 ? difference - 15 : 0,
      }
    }

    // Second half
    if (game.second_half && !game.full_time) {
      const difference = differenceInMinutes(
        now,
        fromZonedTime(new Date(game.second_half), 'UTC')
      )

      return {
        minute: Math.min(45 + difference, 90),
        extraTime: difference > 45 ? difference - 45 : 0,
      }
    }

    // Half time
    if (game.half_time && !game.second_half) {
      return undefined
    }

    // First half
    if (game.matchday && !game.half_time) {
      const difference = differenceInMinutes(now, kickoffTime)

      return {
        minute: Math.min(difference, 45),
        extraTime: difference > 45 ? difference - 45 : 0,
      }
    }

    // Else we're full time
    return {
      minute: game.extra_time_full_time ? 120 : 90,
      extraTime: 0,
    }
  }, [game, kickoffTime])

  const penaltyShootoutGoals = useMemo(() => {
    if (!game) {
      return {
        home: 0,
        away: 0,
      }
    }

    const goals = sortedUpdates.filter(
      (update) => update.type === 'goal' && update.penalty_shootout === true
    )

    return {
      home: goals.filter((goal) => goal.team?.uuid === game.home_team.uuid)
        .length,
      away: goals.filter((goal) => goal.team?.uuid === game.away_team.uuid)
        .length,
    }
  }, [game, sortedUpdates])

  const matchScores = useMemo(() => {
    if (!game) {
      return {
        home: undefined,
        away: undefined,
      }
    }

    return {
      home: game.home_team_goals === '' ? undefined : game.home_team_goals,
      away: game.away_team_goals === '' ? undefined : game.away_team_goals,
    }
  }, [game])

  return {
    sortedUpdates,
    matchEvents,
    lastUpdateTime,
    matchIsLive,
    matchLiveLabel,
    matchTiming,
    penaltyShootoutGoals,
    matchScores,
  }
}

import { createGlobalStyle, css } from 'styled-components'

import { interFont } from '../modules/shared/fonts/inter'

export const GlobalStyle = createGlobalStyle`
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

#__next {
  min-height: 100%;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  ${() =>
    css`
      ${interFont.style}
    `}
}

body.overlay-open {
  overflow: hidden;
  max-height: 100vh;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Site wide styles */
html {
  background-color: ${({ theme }) => theme.Colors.Neutrals.Black}
}

::selection {
  background: #4999176e;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// Crowd manager
.sc-camp {
  width: 100%;
}

// CookieBanner
.cookie-though {
  ${({ theme }) => css`
    /* The colors used to style the modal */
    --ct-primary-400: ${theme.Colors.Primary.Base};
    --ct-primary-300: ${theme.Colors.Primary.Base};
    --ct-primary-200: ${theme.Colors.Primary.Base};
    --ct-primary-100: ${theme.Colors.Primary.Base};
    --ct-secondary-400: ${theme.Colors.Neutrals.Black};

    /* The colors used to style: customizationButton, headerDescription and cookiePolicy */
    --ct-text: ${theme.Colors.Neutrals.Black};

    /* The colors used to style the title elements */
    --ct-subtitle: var(--ct-primary-300);
    --ct-title: var(--ct-primary-400);

    /* The color used to style the cookie svg */
    --ct-cookie: var(--ct-primary-400);

    /* The colors used to style a policy */
    --ct-policy: var(--ct-white);
    --ct-policy-enabled: var(--ct-primary-400);
    --ct-policy-text: var(--ct-primary-400);
    /* --ct-policy-text: var(--ct-white); */
    --ct-policy-text-enabled: var(--ct-white);
    --ct-policy-essential: var(--ct-whit);

    /* The colors used to style the sliders */
    --ct-slider-primary: var(--gray-400);
    --ct-slider-secondary: var(--gray-300);
    --ct-slider-enabled-primary: ${theme.Colors.Neutrals.White};
    --ct-slider-enabled-secondary: ${theme.Colors.Neutrals.Black};

    /* The font color of the text inside a policy when it's enabled  */
    --ct-enabled-option-color: var(--ct-white);

    /* The white color */
    --ct-white: ${theme.Colors.Neutrals.White};

    /* The margin underneath text elements */
    --ct-text-margin: 0.25rem;

    /* The padding of the modal and margin of the banner, toggleButton and customization */
    --ct-default-padding: 1rem;

    /* The padding/margin used to seperate the options and the permission buttons */
    --ct-seperator-padding: 0.5rem;

    /* The font size of the header title */
    --ct-heading-font-size: 1.25rem;

    /* The font size of the header sub title */
    --ct-sub-heading-font-size: 0.875rem;

    /* The font size of text */
    --ct-text-font-size: 0.75rem;

    /* The styling of the customize button and permission buttons */
    --ct-button-font-size: 0.8125rem;
    --ct-button-radius: 2px;
    --ct-button-border: 0;
    --ct-button-padding: 0.5rem;
    --ct-button: var(--ct-primary-400);
    --ct-button-color: var(--ct-white);
    --ct-button-secondary-border: 0;
    --ct-button-secondary: var(--ct-secondary-400);
    --ct-button-secondary-color: var(--ct-white);

    /* The font and font weight used in the app */
    --ct-primary-font: ${theme.Typography.BodyLargeRegular.Desktop[
      'font-family'
    ]};
    --ct-primary-font-weight: 400;

    /* The font and font weight used for header description and policy description */
    --ct-secondary-font: ${theme.Typography.BodyLargeRegular.Desktop[
      'font-family'
    ]};
    --ct-secondary-font-weight: 400;

    /* The z-index you want the root container to have */
    --ct-z-index: 9999;
  `}
}
`

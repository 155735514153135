import { UserProvider } from '@auth0/nextjs-auth0/client'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { captureException } from '@sentry/nextjs'
import { storyblokInit, apiPlugin } from '@storyblok/react'
import { init, onPreferencesChanged } from 'cookie-though'
import NextErrorComponent from 'next/error'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from 'styled-components'
import { theme } from 'theme'

import '../../../../node_modules/flag-icons/css/flag-icons.min.css'
import { AuthenticatedUserProvider } from '../modules/authentication/components/authenticated-user-provider'
import { cookieConfig } from '../modules/cookies/consts/CookieConfig'
import { components } from '../modules/storyblok/components'
import { GlobalStyle } from '../styles/GlobalStyle'

import type { AppProps } from 'next/app'
import type { FC } from 'react'

interface Props extends Omit<AppProps, 'Component'> {
  Component: FC
}

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components,
  apiOptions: {
    cache: {
      type: 'none',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { library, config } = require('@fortawesome/fontawesome-svg-core')
config.autoAddCss = false
library.add(fas)

export const App = ({ Component, pageProps }: Props) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { StoryblokBridge, dataLayer } = window
      // Ignore cookie in storyblok preview
      if (StoryblokBridge !== undefined) {
        const storyblokInstance = new StoryblokBridge()
        if (storyblokInstance.isInEditor()) return
      }

      // Initialize cookie outside of storyblok preview
      init(cookieConfig)
      onPreferencesChanged(() =>
        dataLayer.push({ event: 'preferences_changed' })
      )
    }
  }, [])

  const { key, ...props } = pageProps

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        console.error(error)
        captureException(error)
        return <NextErrorComponent statusCode={error.statusCode} />
      }}
    >
      <UserProvider>
        <AuthenticatedUserProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Component key={key} as {...props} />

            <Toaster
              position="bottom-center"
              toastOptions={{
                // Define default options
                duration: 5000,
                style: {
                  padding: '16px',
                  borderRadius: 0,
                  border: `1px solid ${theme.Colors.Components.Toaster.Border}`,
                  color: theme.Colors.Components.Toaster.Text,
                  backgroundColor: theme.Colors.Components.Toaster.Fill,
                },
                error: {
                  style: {
                    border: `1px solid ${theme.Colors.Components.Toaster.ErrorBorder}`,
                    color: theme.Colors.Components.Toaster.ErrorText,
                    backgroundColor: theme.Colors.Components.Toaster.ErrorFill,
                  },
                },
                success: {
                  style: {
                    border: `1px solid ${theme.Colors.Components.Toaster.SuccessBorder}`,
                    color: theme.Colors.Components.Toaster.SuccessText,
                    backgroundColor:
                      theme.Colors.Components.Toaster.SuccessFill,
                  },
                },
              }}
            />
          </ThemeProvider>
        </AuthenticatedUserProvider>
      </UserProvider>
    </ErrorBoundary>
  )
}

export default App
